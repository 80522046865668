import React from 'react';

const Contact = () => {
  return (
    <section className="bg-gray-200 py-28"> {/* Adjusted padding */}
      <div className="container mx-auto px-4">
        <div className="text-center mb-10">
          <span className="font-billion-dreams block text-4xl font-bold">
            Contactez nous
          </span>
        </div>
        
        <div className="md:flex">
          <div className="md:w-1/2 mb-8 md:mb-0">
            <h1 className='font-billion-dreams text-5xl text-center md:text-left'>Horaires d'ouverture</h1>
            <div className="mt-4 text-center">
              <h2 className="font-billion-dreams text-2xl">lundi au Jeudi <span className="block md:inline">15:30 - 23:00</span></h2>
              <h2 className="font-billion-dreams text-2xl mt-4">Vendredi <span className="block md:inline">15h30 - 01:00</span></h2>
              <h2 className="font-billion-dreams text-2xl mt-4">Samedi <span className="block md:inline">14:00 - 01:00</span></h2>
              <h2 className="font-billion-dreams text-2xl mt-4">Dimanche <span className="block md:inline">14:00 - 23:00</span></h2>
            </div>
            <div className="text-lg font-bold mt-8 text-center md:text-left">63 Mnt de la Grande-Côte, 69001 Lyon</div>
          </div>

          <div className="md:w-1/2">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2783.0199809037035!2d4.8291324758386205!3d45.77078981293104!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47f4ebd229257617%3A0x89cc554b547f2262!2sLa%20Maison%20des%20D%C3%A9lices!5e0!3m2!1sfr!2sfr!4v1692709856129!5m2!1sfr!2sfr"
              frameborder="0"
              allowfullscreen=""
              aria-hidden="false"
              tabindex="0"
              className="w-full h-96" // Adjust height as needed
            ></iframe>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Contact;
