import React from 'react'
import Navbar from '../components/Navbar';
import About from '../components/About';

import Footer from '../components/Footer';

function Histoire() {
  return (
    <div >
        <Navbar />
        <About />
        <Footer />
    </div>
  )
}

export default Histoire