import React from "react";

const Button = ({ text, href }) => {
    return (
        <div className="flex justify-start items-center">
            <a
                href={href}
                className="animate-bounce inline-block px-9 py-3 bg-white border-4 border-black text-black font-semibold text-xl rounded-lg shadow-md hover:shadow-lg transform hover:-rotate-2 transition-transform duration-200 ease-in-out"
                style={{  boxShadow: "0 4px #000" }}
                target="_blank" rel="noopener noreferrer"
            >
                {text}
            </a>
        </div>
    );
};

export default Button;
