import React, { useState } from "react";
import { RxHamburgerMenu } from "react-icons/rx";
import { AiOutlineClose } from "react-icons/ai";
import { FaInstagram, FaFacebookF,  FaTiktok } from 'react-icons/fa';
import { SiUbereats, SiDeliveroo  } from "react-icons/si";

import logo from "../../assets/images/logo2_lmdd.png"
import { Link } from "react-router-dom";



const Navigation = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const navLinks = [
    { href: "/", label: "ACCUEIL" },
    { href: "/histoire", label: "QUI SOMMES-NOUS ?" },
    { href: "/nosdelices", label: "NOTRE CARTE" },
    { href: "/contact", label: "CONTACT" },

  ];
  return (
    <>
      <header className="fixed bg-white sm:px-8 px-4 py-2 z-10 w-full mb-2">
        <nav className="flex justify-between items-center max-container">
          <a href="/" className="text-3xl font-bold">
            <img src={logo} className="w-20"/>
          </a>
          <ul className="text-black  flex-1 flex justify-center items-center gap-16 max-lg:hidden">
            {navLinks.map((item) => (
              <li key={item.label}>
                <Link
                  to={item.href}
                  className="font-sans   leading-normal text-3xl text-slate-gray"
                >
                  {item.label}
                </Link>
              </li>
            ))}
          </ul>
          <div className="text-white flex gap-2 text-lg leading-normal font-medium font-montserrat  wide:mr-24">
            <a className="mr-4 text-blue-500" href="https://www.facebook.com/lmdd.lyon"><FaFacebookF /></a>
            <a className="mr-4 text-pink-500"href="https://www.instagram.com/lmddlyon/?hl=fr/"><FaInstagram /></a>
            <a className="mr-4 text-black" href="https://www.tiktok.com/@lmdd.lyon"><FaTiktok /></a>
          </div>
          <div
            className="hidden max-lg:block cursor-pointer"
            onClick={() => {
              setIsMenuOpen(!isMenuOpen);
            }}
          >
            <RxHamburgerMenu className="text-black text-4xl" />
          </div>
        </nav>
      </header>
      {isMenuOpen && (
        <div>
          <nav className="fixed top-0 right-0 left-0 bottom-0 lg:bottom-auto bg-white  z-10	">
            <div
              className="hidden max-lg:block fixed right-0  px-8 py-4 cursor-pointer"
              onClick={() => {
                setIsMenuOpen(!isMenuOpen);
              }}
            >
              <AiOutlineClose className="text-4xl" />
            </div>
            <ul className=" lg:hidden flex flex-col items-center justify-center h-full ">
              {navLinks.map((item) => (
                <li key={item.label}>
                  <a
                    href={item.href}
                    className="font-sans  leading-normal text-2xl text-black"
                  >
                    {item.label}
                  </a>
                </li>
              ))}
              
            </ul>
          </nav>
        </div>
      )}
    </>
  );
};
export default Navigation;