
import { Carousel } from 'flowbite-react';

import img1 from '../images/img1.jpg'
import img2 from '../images/img2.jpg'
import img3 from '../images/img3.jpg'
import img4 from '../images/img4.jpg'
import img5 from '../images/img5.jpg'
import img6 from '../images/img6.jpg'
import img7 from '../images/img7.jpg'
import img8 from '../images/img8.jpg'
import img9 from '../images/img9.jpg'


function Slider() {
  return (
    <div className=" h-128 pb-4 mt-4">
      <Carousel>
        <img className="w-full	" src={img1} alt="..." />
        <img  className="w-full	" src={img2} alt="..." />
        <img className="w-full	" src={img3} alt="..." />
        <img className="w-full	" src={img4} alt="..." />
        <img className="w-full	" src={img5} alt="..." />
        <img className="w-full	" src={img6} alt="..." />
        <img className="w-full	" src={img7} alt="..." />
        <img className="w-full	" src={img8} alt="..." />
        <img className="w-full	" src={img9} alt="..." />



      </Carousel>
    </div>

  );
}

export default Slider;
