import React from 'react';
import carte1 from "../../assets/images/Diapositive1.png";
import carte2 from "../../assets/images/Diapositive2.png";
import carte3 from "../../assets/images/Diapositive3.png";
import Button from '../Button/Button';
import SliderComponent from '../Slider/Slider';

import diapo1 from '../images/diapo1.png'
import diapo2 from '../images/diapo2.png'
import diapo3 from '../images/HOT DOG.png'

import lacarte from '../../assets/documents/LA-CARTE.pdf'
const Carte = () => {


    
    return (
        <section className="  py-28 bg-pink-500"> {/* Adjust the padding and background color as needed */}
            <div className="container mx-auto px-4">
                <div className="flex flex-wrap">
                    <div className="w-full">
                        <div className="text-center">
                     
                            <h2 className="font-billion-dreams text-5xl  mb-10 font-bold"> {/* Font size and margin */}
                                 Menu 
                            </h2>

                        </div>
                    </div>
                </div>
                <div className="flex flex-wrap items-center justify-center">
           

                    <div className="hover:scale-125 transition-transform duration-300 w-full md:w-1/3 px-2 mb-4">
                        <div className="overflow-hidden rounded-lg">
                            
                        <div>
                            <img className="" src={diapo1} width="100%"  />

                        </div>
                 
                        </div>
                    </div>
                    <div className="hover:scale-125 transition-transform duration-300  w-full md:w-1/3 px-2 mb-4">
                        <div className="overflow-hidden rounded-lg">
                            
                        <div>
                            <img className="" src={diapo2} width="100%"  />

                        </div>  
                 
                        </div>
                    </div>
                    <div className="hover:scale-125 transition-transform duration-300  w-full md:w-1/3 px-2 mb-4">
                        <div className="overflow-hidden rounded-lg">
                            
                        <div>
                            <img className="" src={diapo3} width="100%"  />

                        </div>  
                 
                        </div>
                    </div>

                    
                   
                </div>
                <div className="flex flex-wrap justify-center">
                    <a href={lacarte} download="LA CARTE">
                        <Button text="la carte" />
                    </a>
                    </div>

            </div>

        </section>
    );
}

export default Carte;
