import React from 'react'
import Footer from '../components/Footer';
import Navbar from '../components/Navbar';
import Carte from '../components/Carte';

function Nosdélices() {
  return (
    <div >
                <Navbar />
                <Carte />

                <Footer />


    </div>
  )
}

export default Nosdélices