import React from 'react'
import { GlobalStyle } from '../globalStyles';
import About from '../components/About';
import Carte from '../components/Carte';
import Etablissement from '../components/Etablissement';
import Footer from '../components/Footer';
import Contact from '../components/Contact';
import HeroSection from '../components/Hero';
import Delivery from '../components/Delivery/Delivery';
import Slider from '../components/Slider/Slider';
function Home() {
  return (
    <div >
           <GlobalStyle />
      <HeroSection />
      <Delivery />
      <About />
      <Carte />
      <Etablissement />
      <Contact />
      <Footer />
    </div>
  )
}

export default Home