import React from 'react';
import uberEatsLogo from '../images/logo-uber.png'; // Ajustez le chemin selon le besoin
import deliverooLogo from '../images/logo-deliveroo.png'; // Ajustez le chemin selon le besoin

const Delivery = () => {
    return (
        <div className="bg-black text-white  flex flex-wrap md:flex-nowrap justify-between items-center p-8 relative">
            <div className="flex flex-col items-start mb-4 md:mb-0">
                <div className="font-sans  text-3xl leading-9">Faites vous livrer</div>
                <div className="font-sans  text-3xl leading-9 text-white text-opacity-50">Nos gourmandises à la maison ! </div>
            </div>
            <div className="flex justify-end items-center">
                <a href="https://www.ubereats.com/fr/store/la-maison-des-delices/2Ec3B1i-TbKmL2lZtXYUTg?diningMode=DELIVERY" target="_blank" rel="noopener noreferrer" className="ml-10">
                    <img  className="animate-pulse" src={uberEatsLogo} alt="Uber Eats" width="45%" />
                </a>
                <a href="https://deliveroo.fr/fr/menu/lyon/hotel-de-ville/la-maison-des-delices-l" target="_blank" rel="noopener noreferrer" className="ml-10">
                    <img src={deliverooLogo} className="animate-pulse" alt="Deliveroo" width="30%" />
                </a>
            </div>
        </div>
    );
};

export default Delivery;
