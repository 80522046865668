import { Route, Routes } from 'react-router';
import './App.css';
import Navigation from './components/Navbar';

import Home from './pages/Home';
import Nosdélices from './pages/Nosdélices';
import Histoire from './pages/Histoire';
import ContactSec from './pages/Contact';


function App() {
  return (
    <>
     <Routes>
        <Route path="/" element={<Home />}/>
          <Route path="/nosdelices" element={<Nosdélices />} />
          <Route path="/histoire" element={<Histoire/>} />
          <Route path="/contact" element={<ContactSec/>} />


          {/* Using path="*"" means "match anything", so this route
                acts like a catch-all for URLs that we don't have explicit
                routes for. */}
      </Routes>   
    </>
  );
}

export default App;
