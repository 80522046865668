import React from 'react'
import Navbar from '../components/Navbar';
import Contact from '../components/Contact';

import Footer from '../components/Footer';
function ContactSec() {
  return (
    <div >
        
        
        <Navbar />

        <Contact />
      <Footer />
    </div>
  )
}

export default ContactSec