import React from 'react';
import phoneIcon from '../../assets/images/Phone.svg'; // Adjust the path as needed
import emailIcon from '../../assets/images/Email.svg'; // Adjust the path as needed
import locationIcon from '../../assets/images/Location.svg'; // Adjust the path as needed

const Footer = () => {
    return (
        <div className=" border-t-3 border-black bg-white">
            <div className="container mx-auto px-4">

            <div className="max-w-7xl mx-auto grid grid-cols-3 gap-8 py-8">
    <a href="tel:0627949599" className="flex animate-pulse flex-col items-center bg-[#f4e4db] p-6 hover:bg-[#e8d7ce]">
        <img src={phoneIcon} alt="" className="mb-2" />
        <h3 className='font-billion-dreams text-5xl hidden sm:block'>Appelez nous</h3>
        <p className='text-black text-lg hidden sm:block'>06 27 94 95 99</p>
    </a>

    <a href="mailto:contact@lmdd-lyon.fr?subject=LA%20MAISON%20DES%20DELICES" className="flex animate-pulse flex-col items-center bg-[#f4e4db] p-6 hover:bg-[#e8d7ce]">
        <img src={emailIcon} alt="" className="mb-2" />
        <h3 className='font-billion-dreams text-5xl hidden sm:block'>Contactez nous</h3>
        <p className='text-black text-lg hidden sm:block'>contact@lmdd-lyon.fr</p>
    </a>

    <a href="https://www.google.com/maps/dir//La+Maison+des+D%C3%A9lices+..." className="flex animate-pulse flex-col items-center bg-[#f4e4db] p-6 hover:bg-[#e8d7ce]">
        <img src={locationIcon} alt="" className="mb-2" />
        <h3 className='font-billion-dreams text-5xl hidden sm:block'>Venez chez nous</h3>
        <p className='text-black text-lg hidden sm:block'>63 Mnt de la Grande-Côte</p>
    </a>
</div>




            </div>
            <div className="bg-[rgba(19,19,19,.05)] py-6 mt-20 text-center">
                <div className="container mx-auto px-4">
                    <div>LA MAISON DES DELICES TOUS DROIT RESERVEE 2023.</div>
                </div>
            </div>
        </div>
    );
};

export default Footer;
