import React, { Component } from "react";
import image1 from '../images/images1.jpeg'
import { Tilt } from 'react-tilt';
import nuttshake from '../images/nutella-milkshake02.jpg'
import almoune from '../images/almoune-produit01.jpg'
import oreoshake from '../images/oreo-milkshake04.jpg'
import bapamanga from '../images/cocktails007_thumbnail.jpg'
import smoothie from '../images/cocktails002_thumbnail.jpg'
import Carousel from "../Slider/Slider";

const slides = [
  '../images/img1.jpg',
  '../images/img2.jpg',
  '../images/img3.jpg',
  '../images/img4.jpg',
 '../images/img5.jpg',
  '../images/img6.jpg',
  '../images/img7.jpg',
 '../images/img8.jpg',
 '../images/img9.jpg',
]


class AboutIntro extends Component {
  render() {
    return (
      <section className="">
        <div className="sec-space no-bottom bg-gray">
          <div className="flex flex-wrap">
            <div className="w-full">
              <div className="text-center">
                <span className="font-billion-dreams  block mb-4 text-4xl  font-bold"> {/* Font class and color */}
                  Qui sommes nous ?
                </span>
              </div>
            </div>
          </div>
          <div className="container">
            <div className="row">
              <div className="col-lg-5">
                <article className="abt-resturant justify-text-center">
                  <time className=" fs-20 d-block mb-2">
                    Depuis 2020.
                  </time>
                  <h2 className="font-billion-dreams text-pink-500 h1 mb-3 font-weight-bold">
                    Bienvenue à La Maison des Délices.
                  </h2>
                  <p className="text-black text-justify font-sans">
                    Votre escale gourmande incontournable !
                  </p><br />
                  <p className="text-black text-justify font-sans">
                    Ici, la passion des crêpes se ressent dans chaque bouchée. Nous sommes fiers de vous proposer un large éventail de délices, allant de nos <strong className="text-black font-sans">crêpes authentiques et moelleuses</strong> à nos <strong className="text-black font-sans">gaufres croustillantes et dorées</strong>, en passant par nos <strong className="text-black font-sans">milkshakes onctueux</strong> et nos <strong className="text-black font-sans">smoothies frais et vitaminés</strong>.
                  </p><br />
                  <p className="text-black text-justify font-sans">
                    <strong className="font-sans">La Maison des Délices</strong> est plus qu'une simple crêperie ; c'est une expérience où chaque plat est préparé avec amour et dévouement. Nous sélectionnons avec soin nos ingrédients pour garantir une qualité supérieure et un goût inoubliable. Nos recettes, à la fois traditionnelles et innovantes, sont le reflet de notre amour pour la gastronomie et notre désir de partager cette passion avec vous.
                  </p><br />
                  <p className="text-black text-justify font-sans">
                    Située au cœur de notre charmante ville, notre crêperie est l'endroit parfait pour se retrouver en famille, entre amis, ou pour un moment en solo, autour d'un repas réconfortant et délicieux. Dans notre établissement, chaque client est traité comme un invité de marque et nous nous efforçons de vous offrir un service chaleureux et attentif.
                  </p><br />
                  <p className="text-black text-justify font-sans">
                    En franchissant les portes de <strong className="font-sans">La Maison des Délices</strong>, vous ne venez pas seulement pour manger, vous venez pour vivre une expérience gourmande unique qui vous transportera dans un univers de saveurs exceptionnelles.
                  </p><br />
                  <p className="text-black  text-justify font-sans">
                    Nous vous invitons à vous joindre à nous pour découvrir et redécouvrir ces délices qui éveilleront vos papilles et réchaufferont vos cœurs.
                  </p> <br />
                  <p className="text-black text-justify font-sans mb-5">
                    À très bientôt chez La Maison des Délices !
                  </p>
                </article>
              </div>

              <div className="max-w-lg">
                <Carousel />
                
              </div>


            </div>
          </div>
        </div>
      </section>
    );
  }
}
export default AboutIntro;
