import React, { useState } from 'react';
import Navbar from '../Navbar';
import Sidebar from '../Sidebar';
import {
  HeroContainer,
  HeroContent,
  HeroItems,
  HeroH1,
  HeroP,
  HeroBtn
} from './HeroElements';

import Button from '../Button/Button';



const Hero = () => {
  
  return (
    <HeroContainer>
      <Navbar />
      <HeroContent>
        <HeroItems>
          <HeroH1>
           <h1 className='font-sans text-white'>La Maison Des Délices </h1> 
          </HeroH1>
          <HeroP> <p className='font-sans text-white'>BIENVENUE</p></HeroP>
          <Button
                        text="Commander"
                        href="https://linktr.ee/lmddlyon1"
            />
        </HeroItems>
      </HeroContent>
    </HeroContainer>
  );
};

export default Hero;